import { defineStore } from "pinia"
import {
  Camera,
  CamerasByExid,
  CameraStatus,
  EvercamApiError,
} from "@evercam/shared/types"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useProjectStore } from "@evercam/dashboard/stores/project"
import { useAccountStore } from "@evercam/dashboard/stores/account"
import { useNuxtApp } from "#app"
import { Socket } from "phoenix-socket"
import axios from "@evercam/shared/api/client/axios"

type ActiveSocket = {
  domain: string
  socket: Socket
}

type CameraState = {
  cameras: Camera[] | []
  selectedCamera: Camera | null
  cameraStatusMessage: string | null
  isFetchingUserCameras: boolean
  isCameraTab: boolean
  liveViewSocket: Socket
  activeLiveViewSockets: ActiveSocket[]
  isConnectingToSocket: boolean
}

export const useCameraStore = defineStore("camera", {
  state: (): CameraState => ({
    cameras: [],
    selectedCamera: null,
    cameraStatusMessage: null,
    isFetchingUserCameras: false,
    isCameraTab: true,
    liveViewSocket: null,
    activeLiveViewSockets: [],
    isConnectingToSocket: false,
  }),
  actions: {
    async selectCamera(cameraExid) {
      if (!cameraExid) {
        return
      }

      const camera = this.getCameraByExid(cameraExid)
      if (camera) {
        this.selectedCamera = camera
        this.getCameraStatusMessage(camera)

        return
      }

      try {
        let { cameras } = await EvercamApi.cameras.getCameraById(cameraExid)
        const camera = cameras[0]

        if (!camera?.isPublic && !useAccountStore().isWidget) {
          return
        }
        this.selectedCamera = camera
        this.getCameraStatusMessage(camera)
      } catch (e) {
        const error = e as EvercamApiError
        if (
          error.response?.status === 500 &&
          !useNuxtApp().nuxt2Context.isDev
        ) {
          useNuxtApp().nuxt2Context.app.router.push("/server-down")
        } else {
          console.error(
            `Camera (${cameraExid}) not found. User: ${useAccountStore().email}`
          )
        }
      }
    },
    async fetchUserCameras() {
      try {
        this.isFetchingUserCameras = true
        const { cameras } = await EvercamApi.cameras.getCameras()
        this.cameras = cameras.map((camera) => ({
          ...camera,
          thumbnailUrl: this.getCameraThumbnailUrl(camera.exid, "360p"),
          largeThumbnailUrl: this.getCameraThumbnailUrl(camera.exid, false),
        }))
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t("content.fetch_resource_failed", {
            resource: "user cameras",
          }) as string,
          error,
        })
      } finally {
        this.isFetchingUserCameras = false
      }
    },
    async updateCameraProperty({ modifiedCamera, modifiedProperty }) {
      const cameras = this.cameras?.map((camera) => {
        if (camera.id == modifiedCamera.exid) {
          camera[modifiedProperty] = modifiedCamera[modifiedProperty]
        }

        return camera
      })

      this.cameras = cameras
    },
    async getCameraStatusMessage(camera) {
      if (camera?.status === CameraStatus.OfflineScheduled) {
        this.cameraStatusMessage = `Cause: ${camera?.offlineReason}`

        return
      }

      this.cameraStatusMessage = useNuxtApp().vue2App.$i18n.t(
        `content.camera_status.${camera?.status}`
      )

      if (
        ![
          CameraStatus.Offline,
          CameraStatus.UnderMaintenance,
          CameraStatus.WaitingForSiteVisit,
        ].includes(camera?.status)
      ) {
        return
      }

      try {
        const log = await EvercamApi.cameras.getCameraLastPublicNote(camera.id)
        if (log?.details?.customMessage) {
          this.cameraStatusMessage = log.details?.customMessage
        }
      } catch (error) {
        useNuxtApp().nuxt2Context.$errorTracker.save(error)
      }
    },
    getCameraByExid(cameraExid) {
      return this.cameras?.find((camera) => camera?.id === cameraExid)
    },
    connectLiveViewSocket(cameraStreamingServer) {
      let socket = this.activeLiveViewSockets.find(
        (socket) => socket.domain === cameraStreamingServer
      )?.socket

      if (socket && socket.channels.length > 0) {
        return socket
      }

      this.isConnectingToSocket = true

      socket = this.getLiveViewSocket(cameraStreamingServer)
      this.connectSocket(socket)
      this.registerLiveViewActiveSocket(cameraStreamingServer, socket)

      this.isConnectingToSocket = false

      return socket
    },
    connectSocket(socket) {
      if (socket.isConnected()) {
        return
      }
      try {
        socket.connect()
      } catch (e) {
        console.error(e)
      }
    },
    getLiveViewSocket(cameraStreamingServer) {
      const protocol = useNuxtApp().nuxt2Context.isDev ? "ws" : "wss"

      return new Socket(`${protocol}://${cameraStreamingServer}/socket`, {
        params: {
          token: useAccountStore().token,
        },
      })
    },
    registerLiveViewActiveSocket(domain, socket) {
      this.activeLiveViewSockets = [
        ...this.activeLiveViewSockets,
        { domain, socket },
      ]
    },
    setupSocketForSelectedCamera() {
      if (this.improperWebSocketConnection && this.selectedCamera) {
        this.liveViewSocket = this.connectLiveViewSocket(
          this.selectedCamera.streamingServer
        )
      }
    },
    getCameraThumbnailUrl(cameraExid, resolution = "360p") {
      const baseUrl = useNuxtApp().nuxt2Context.$config.public.apiURL
      const queryParams = `authorization=${useAccountStore().token}&view=true`
      if (!resolution) {
        return `${baseUrl}/cameras/${cameraExid}/thumbnail?${queryParams}`
      }
      const resolutionMap = {
        "360p": useNuxtApp().nuxt2Context.$imgproxy.get360pResizedImageUrl,
        "720p": useNuxtApp().nuxt2Context.$imgproxy.get720pResizedImageUrl,
        "1080p": useNuxtApp().nuxt2Context.$imgproxy.get1080pResizedImageUrl,
      }

      const resizeFunction = resolutionMap[resolution] || resolutionMap["360p"]

      return resizeFunction(
        `${baseUrl}/cameras/${cameraExid}/thumbnail?${queryParams}`
      )
    },
    async updateCameraName(cameraExid, name) {
      try {
        await EvercamApi.cameras.updateCamera(cameraExid, { name })
        this.updateCameraProperty({
          modifiedCamera: { exid: cameraExid, name },
          modifiedProperty: "name",
        })
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t("content.update_success", {
            resource: "camera name",
          }) as string
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t("content.update_failed", {
            resource: "Camera name",
          }) as string,
          error,
        })
      }
    },
    async updateCameraVisibility(cameraExid, isPublic) {
      try {
        await EvercamApi.cameras.updateCamera(cameraExid, { isPublic })
        this.updateCameraProperty({
          modifiedCamera: { exid: cameraExid, isPublic },
          modifiedProperty: "isPublic",
        })
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t("content.update_success", {
            resource: "Camera visibility",
          }) as string
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t("content.update_failed", {
            resource: "Camera visibility",
          }) as string,
          error,
        })
      }
    },
  },
  getters: {
    improperWebSocketConnection(): boolean {
      if (
        !this.selectedCamera ||
        !this.liveViewSocket ||
        this.liveViewSocket.length === 0
      ) {
        return true
      }

      return !this.liveViewSocket.endPoint.includes(
        this.selectedCamera?.streamingServer
      )
    },
    isCameraOnline(): boolean {
      return this.selectedCamera?.status === CameraStatus.Online
    },
    isCameraOffline(): boolean {
      return this.selectedCamera?.status === CameraStatus.Offline
    },
    isCameraWaiting(): boolean {
      return this.selectedCamera?.status === CameraStatus.Waiting
    },
    isCameraHasSharingRights(): boolean {
      return !!this.selectedCamera?.rights
        ?.split(",")
        ?.find((action) => ["share", "edit", "delete"].includes(action))
    },
    isCameraSharedWithUser(): boolean {
      return !!(
        useAccountStore().token &&
        this.selectedCamera?.id &&
        this.cameras?.find((camera) => camera.id === this.selectedCamera?.id)
      )
    },
    isCameraPublic: (state): boolean => state.selectedCamera?.isPublic,
    selectedCameraExid: (state): string => state.selectedCamera?.id,
    selectedCameraTimezone(): string {
      return this.selectedCamera?.timezone || "Europe/Dublin"
    },
    cameraRoute(): string {
      return `${useProjectStore().projectRoute}/${this.selectedCameraExid}`
    },
    hasCameraLiveView(): boolean {
      return ![CameraStatus.Decommissioned, CameraStatus.OnHold].includes(
        this.selectedCamera?.status
      )
    },
    camerasByExid(): CamerasByExid {
      return this.cameras.reduce(
        (acc, camera) => ({
          ...acc,
          [camera.exid]: camera,
        }),
        {}
      )
    },
    cameraUrl(): string {
      return `${axios.env.baseUrl}/cameras/${this.selectedCameraExid}`
    },
    getThumbnailSrc(): string {
      return `${this.cameraUrl}/thumbnail?${this.getQueryParams}`
    },
    getQueryParams(): string {
      return `authorization=${useAccountStore().token}&view=true`
    },
  },
})
